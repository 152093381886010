import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['viewBlock', 'hideableLink', 'editBlock', 'focus']

  edit(event) {
    this.toggle(true)
    this.setFocus()
    event.preventDefault()
  }

  cancel(event) {
    this.toggle(false)
    event.preventDefault()
  }

  toggle(edit) {
    this.viewBlockTarget.classList.toggle('d-none', edit)
    this.editBlockTarget.classList.toggle('d-none', !edit)
    if(this.hasHideableLinkTarget) {
      this.hideableLinkTarget.classList.toggle('d-none', edit)
    }
  }

  setFocus() {
    if (this.focusTarget.nodeName == 'TEXTAREA'){
      // Not all potential focus targets will have selectionStart, selectionEnd, or lengths.
      this.focusTarget.selectionStart = this.focusTarget.selectionEnd = this.focusTarget.value.length
    }
    this.focusTarget.focus()
  }
}
